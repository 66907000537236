@import "_global_variables.scss";
@import "bootstrap/scss/bootstrap.scss";


@import "utilities/images";
@import "utilities/fonts";
@import "utilities/colors";
@import "utilities/borders";
@import "utilities/controls";
@import "layout/layout";
@import "personal/personal";
@import "personal/fido";
@import "personal/totp";

* {
    font-family: "Roboto", sans-serif;
}


html, body {
    width: 100%;
    height: 100vh;
    font-size: 18px;
}

body {
    overflow-y: scroll;
    margin: 0;
    background-color: $main-second-background-color;
}

html {
    min-height: 100%;
}

.register-background {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.register-form-container {
    background-color: $white;
    width: 100%;
    padding: 24px 48px 24px 48px;
    border-radius: 16px;
    margin-top: 100px;
}


.personal-container {
    width: 70%;    
    border-left: $border;
    border-right: $border;
    background-color: $main-first-background-color;
    min-height: 100vh;
    margin: auto;
}

@media all and (max-width: 1210px){
    .personal-container {
        width: 100%;
    }
}

.modal-dialog {
    max-width: 420px;
}
.modal-body {
    padding: 1.3rem 3.3rem;
}
@media screen and (max-width: 360px){
    .modal-body {
        padding: 1rem;
    }
}
.modal-content {
    border-radius: 12px;
}

.gap-4 {
    gap: 4px !important;
}
.gap-8 {
    gap: 8px;
}
.gap-16 {
    gap: 16px;
}
.gap-20 {
    gap: 20px;
}
.gap-32 {
    gap: 32px;
}
.column-gap-4 {
    column-gap: 4px;
}

.spinner {
    @include large-icon;
    background-image: $spinner;
}
.check-mark-big {
    @include large-icon;
    background-image: $check-mark-big;
}
.attention-big {
    @include large-icon;
    background-image: $attention-big;
}
.person-icon {
    @include normal-icon;
    background-image: $person-icon;
}
.bucket-icon {
    @include normal-icon;
    background-image: $bucket-icon;
}
.bucket-icon:hover {
    background-image: $bucket-red-icon;
}
.edit-icon {
    @include small-icon;
    background-image: $edit-icon;
}

.fade-modal {
    animation-name: fade-modal;
    animation-duration: 2s;
}
@keyframes fade-modal {
    from {
        opacity: .0;
    }
  
    to {
        opacity: 1;
    }
  }



.certificatelist_certificate {
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: #117788;
    width: 100%;
    color: #ffffff;

    &:hover {
        text-decoration: none;
        cursor: pointer;
        background-color: rgb(8, 103, 119);
    }

    &.invalid {
        cursor: default;
        background-color: #dfdfdf;
        color: #888888;

        & .info_date {
            background-color: #dfdfdf;
            color: #dd2233;
        }
    }
}


.certificate_body {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.certificate_icon {
    height: 25px;
    width: 35px;
    background: url("./images/cert_icon.png") no-repeat;
}

.certificate_info {
    width: 500px;
}
