.personal {
    margin-top: 60px;
}

.personal-heading {
    font-size: 2rem;
    margin-bottom: 2rem;
    @include font-bolder();
}

.personal-info {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 2rem;
    @include font-bolder();
}

.personal-info__logo {
    background: $user-image;
    @include normal-icon;
}

.personal-info__name {
    font-size: 1.5rem;
}

.personal-logout {
    font-size: 1rem;
    color: $gray-tint;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s;
    &:hover > .personal-logout__text {
        color:$red;
    }
    
    &:hover > .personal-logout__logo {
        background: $logout-image-hover;
    }
    &:hover {
        color: $red-fade;
        background-color: $gray-lighten;
        border-radius: $border-radius;
    }
    padding: 0.5rem;
}

.personal-logout__logo {
    background: $logout-image;
    @include normal-icon;
}

.personal-expiration {
    display: flex;
    gap: 5px;
}

.personal-expiration__text {
    color: $gray-light;
}

.personal-two-factor {
    display: flex;
    gap: 5px;
}

.personal-two-factor__text {
    color: $gray-light;
}

.personal-two-factor__value {
    color: $red;
}

.personal-two-factor__value--on {
    color: $green;
}

.personal-two-factor-heading {
    padding: 4px;
    font-size: 1.5rem;
    @include font-bolder();
    padding: 0.5rem 0 0.5rem 0;
}

.personal-two-factor-blocks {
    display: flex;
    gap: 20px;
}

.personal-two-factor-block {
    flex: 1 1 auto;
    height: 130px;
    border: $border;
    border-radius: $border-radius;
    font-size: 2rem;
    @include font-bolder();
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    & .personal-two-factor-block--done {
        position:absolute;
        background: $done-icon;
        bottom: 1rem;
        right: 1rem;
        @include normal-icon;
    }
}

.personal-add-device-header {
    font-size: 1.25rem;
    @include font-bolder();
    padding: 0.5rem 0 0.5rem 0;
}

.personal-add-device-step {
    color: $gray-light;
}

.item-device {
    border: 1px solid rgba(100, 100, 100, 0.16);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0px;
}



div[disabled]
{
    pointer-events: none;
    opacity: 0.7;
}